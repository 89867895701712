import React, { forwardRef, ReactNode } from 'react';
import { Stack, Box } from '@egym/ui';
import { AppLayoutFeatureComponentProps } from '../../../../AppPreviewerProps';
import { IconWrapper, InfoOutlinedIcon, ShareIcon } from '../../../Icons';
import PreviewContainer from '../../../PreviewContainer';
import PreviewActivityHistoryV2 from '../PreviewActivityHistoryV2';
import PreviewHealthBenefits from '../PreviewHealthBenefits';

const PreviewActivityLevelOnTheTab = forwardRef<HTMLDivElement, AppLayoutFeatureComponentProps>(
  ({ tFunction, palette, previewerState, wrapperSx }, ref) => {
    const daysLeftText = tFunction('appPreviewer.labels.daysLeftCount', { count: 3 });
    const activityPointsText = tFunction('appPreviewer.labels.activityPoints');

    return (
      <div ref={ref}>
        <PreviewContainer wrapperSx={{ px: '15px', ...wrapperSx }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ marginTop: '5px' }}>
            <IconWrapper bgColor={palette.primaryTransparent}>
              <InfoOutlinedIcon htmlColor={palette.primary} />
            </IconWrapper>
            <span style={{ color: 'rgb(0, 0, 0)', fontSize: '18px', lineHeight: '22px', fontWeight: 600 }}>
              {tFunction('appPreviewer.labels.activityLevel') as ReactNode}
            </span>
            <IconWrapper bgColor={palette.primaryTransparent}>
              <ShareIcon htmlColor={palette.primary} />
            </IconWrapper>
          </Stack>
          <div
            style={{
              color: '#c57661',
              fontSize: '18px',
              lineHeight: '22px',
              fontWeight: 700,
              textAlign: 'center',
              marginTop: '25px',
              marginBottom: '7px',
            }}
          >
            {tFunction('appPreviewer.labels.bronze') as ReactNode}
          </div>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              position: 'relative',
              height: '250px',
              color: 'rgb(76, 74, 74)',
            }}
          >
            <img
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '250px',
              }}
              src="/images/previewerDefaultImages/features/activityLevel/@2xring.png"
              alt="progress ring"
            />
            <span
              style={{
                zIndex: 1,
                fontSize: activityPointsText.length > 18 ? '13px' : '15px',
                lineHeight: '20px',
                marginBottom: activityPointsText.length > 18 ? 0 : '10px',
              }}
            >
              {activityPointsText}
            </span>
            <span
              style={{ zIndex: 1, fontSize: '28px', fontWeight: 700, letterSpacing: '-1px', color: 'rgb(33, 33, 33)' }}
            >
              920
            </span>
            <span style={{ zIndex: 1, fontSize: '15px', lineHeight: '20px', marginBottom: '10px' }}>
              {tFunction('appPreviewer.labels.goal') as ReactNode} 1123
            </span>
            <span
              style={{
                display: 'inline-block',
                zIndex: 1,
                fontSize: daysLeftText.length > 15 ? '13px' : '15px',
                maxWidth: '100px',
                textAlign: 'center',
                fontWeight: 700,
                lineHeight: '15px',
                color: 'rgb(33, 33, 33)',
              }}
            >
              {daysLeftText}
            </span>
          </Stack>
        </PreviewContainer>
        <Box
          sx={{
            background: 'white',
            marginBottom: '-20px',
            marginTop: '10px',
            paddingTop: '15px',
            '& > *': {
              ':not(:last-child)': {
                marginBottom: '17px',
              },
            },
          }}
        >
          <PreviewHealthBenefits palette={palette} previewerState={previewerState} tFunction={tFunction} />
          <PreviewActivityHistoryV2 palette={palette} previewerState={previewerState} tFunction={tFunction} />
        </Box>
      </div>
    );
  },
);

export default PreviewActivityLevelOnTheTab;
