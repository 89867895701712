import { AppLayoutFeatureEnum } from '@components';
import { AppDesignWidgetCategory, Orientation, PredefinedTabIcon, PredefinedTabImage, WidgetViewStyle } from '@types';

export const widgetsCategoriesGroups = {
  [AppDesignWidgetCategory.Facility]: [
    AppLayoutFeatureEnum.PreviewExtras,
    AppLayoutFeatureEnum.PreviewLocationFinder,
    AppLayoutFeatureEnum.PreviewFindAClass,
    AppLayoutFeatureEnum.PreviewGuestPass,
    // AppLayoutFeatureEnum.PreviewExploreSchedule,
  ],
  [AppDesignWidgetCategory.Social]: [
    AppLayoutFeatureEnum.PreviewNotificationCenter,
    AppLayoutFeatureEnum.PreviewReferAFriend,
    AppLayoutFeatureEnum.PreviewActivityFeed,
    AppLayoutFeatureEnum.PreviewRanking,
    AppLayoutFeatureEnum.PreviewChallenges,
  ],
  [AppDesignWidgetCategory.Workouts]: [
    AppLayoutFeatureEnum.PreviewTrainingPlans,
    AppLayoutFeatureEnum.PreviewActivityHistory,
    AppLayoutFeatureEnum.PreviewRequestTrainer,
    // AppLayoutFeatureEnum.PreviewVirtualClasses,
    // AppLayoutFeatureEnum.PreviewWorkoutTracking,
    AppLayoutFeatureEnum.PreviewConnectedApps,
  ],
  [AppDesignWidgetCategory.Progress]: [
    AppLayoutFeatureEnum.PreviewActivityLevel,
    AppLayoutFeatureEnum.PreviewBioAge,
    AppLayoutFeatureEnum.PreviewGoalCenter,
  ],
  [AppDesignWidgetCategory.Perks]: [AppLayoutFeatureEnum.PreviewRewards, AppLayoutFeatureEnum.PreviewDeals],
  [AppDesignWidgetCategory.WebWidget]: [
    AppLayoutFeatureEnum.PreviewWebWidget,
    AppLayoutFeatureEnum.PreviewGroupWebWidget,
  ],
};

export enum DrawerWarnings {
  MoveFeaturesToTheLibraryAgreement = 'MoveFeaturesToTheLibraryAgreement',
  MoveFeaturesToAnotherTabForm = 'MoveFeatureToAnotherTabForm',
  FeatureUsed = 'FeatureUsed',
  NotificationsPosition = 'NotificationsPosition',
  MoveFeaturesToTheLibrary = 'MoveFeaturesToTheLibrary',
}

export enum FeatureSize {
  Compact = 'Compact',
  FullTab = 'FullTab',
}

export const nonRemovableFeatures = [
  AppLayoutFeatureEnum.PreviewActivityHistory,
  AppLayoutFeatureEnum.PreviewTrainingPlans,
];

export const predefinedWidgetIcons: PredefinedTabIcon[] = [
  {
    id: 1,
    path: '/images/previewerDefaultImages/widgets/icons/widgetIcon1.svg',
  },
  {
    id: 2,
    path: '/images/previewerDefaultImages/widgets/icons/widgetIcon2.svg',
  },
  {
    id: 3,
    path: '/images/previewerDefaultImages/widgets/icons/widgetIcon3.svg',
  },
  {
    id: 4,
    path: '/images/previewerDefaultImages/widgets/icons/widgetIcon4.svg',
  },
  {
    id: 5,
    path: '/images/previewerDefaultImages/widgets/icons/widgetIcon5.svg',
  },
  {
    id: 6,
    path: '/images/previewerDefaultImages/widgets/icons/widgetIcon6.svg',
  },
  {
    id: 7,
    path: '/images/previewerDefaultImages/widgets/icons/widgetIcon7.svg',
  },
  {
    id: 8,
    path: '/images/previewerDefaultImages/widgets/icons/widgetIcon8.svg',
  },
  {
    id: 9,
    path: '/images/previewerDefaultImages/widgets/icons/widgetIcon9.svg',
  },
  {
    id: 10,
    path: '/images/previewerDefaultImages/widgets/icons/widgetIcon10.svg',
  },
  {
    id: 11,
    path: '/images/previewerDefaultImages/widgets/icons/widgetIcon11.svg',
  },
  {
    id: 12,
    path: '/images/previewerDefaultImages/widgets/icons/widgetIcon12.svg',
  },
  {
    id: 13,
    path: '/images/previewerDefaultImages/widgets/icons/widgetIcon13.svg',
  },
  {
    id: 14,
    path: '/images/previewerDefaultImages/widgets/icons/widgetIcon14.svg',
  },
  {
    id: 15,
    path: '/images/previewerDefaultImages/widgets/icons/widgetIcon15.svg',
  },
  {
    id: 16,
    path: '/images/previewerDefaultImages/widgets/icons/widgetIcon16.svg',
  },
  {
    id: 17,
    path: '/images/previewerDefaultImages/widgets/icons/widgetIcon17.svg',
  },
  {
    id: 18,
    path: '/images/previewerDefaultImages/widgets/icons/widgetIcon18.svg',
  },
  {
    id: 19,
    path: '/images/previewerDefaultImages/widgets/icons/widgetIcon19.svg',
  },
  {
    id: 20,
    path: '/images/previewerDefaultImages/widgets/icons/widgetIcon20.svg',
  },
  {
    id: 21,
    path: '/images/previewerDefaultImages/widgets/icons/widgetIcon21.svg',
  },
  {
    id: 22,
    path: '/images/previewerDefaultImages/widgets/icons/widgetIcon22.svg',
  },
  {
    id: 23,
    path: '/images/previewerDefaultImages/widgets/icons/widgetIcon23.svg',
  },
  {
    id: 24,
    path: '/images/previewerDefaultImages/widgets/icons/widgetIcon24.svg',
  },
  {
    id: 25,
    path: '/images/previewerDefaultImages/widgets/icons/widgetIcon25.svg',
  },
  {
    id: 26,
    path: '/images/previewerDefaultImages/widgets/icons/widgetIcon26.svg',
  },
  {
    id: 27,
    path: '/images/previewerDefaultImages/widgets/icons/widgetIcon27.svg',
  },
  {
    id: 28,
    path: '/images/previewerDefaultImages/widgets/icons/widgetIcon28.svg',
  },
  {
    id: 29,
    path: '/images/previewerDefaultImages/widgets/icons/widgetIcon29.svg',
  },
  {
    id: 30,
    path: '/images/previewerDefaultImages/widgets/icons/widgetIcon30.svg',
  },
  {
    id: 31,
    path: '/images/previewerDefaultImages/widgets/icons/widgetIcon31.svg',
  },
  {
    id: 32,
    path: '/images/previewerDefaultImages/widgets/icons/widgetIcon32.svg',
  },
  {
    id: 33,
    path: '/images/previewerDefaultImages/widgets/icons/widgetIcon33.svg',
  },
  {
    id: 34,
    path: '/images/previewerDefaultImages/widgets/icons/widgetIcon34.svg',
  },
  {
    id: 35,
    path: '/images/previewerDefaultImages/widgets/icons/widgetIcon35.svg',
  },
  {
    id: 36,
    path: '/images/previewerDefaultImages/widgets/icons/widgetIcon36.svg',
  },
  {
    id: 37,
    path: '/images/previewerDefaultImages/widgets/icons/widgetIcon37.svg',
  },
  {
    id: 38,
    path: '/images/previewerDefaultImages/widgets/icons/widgetIcon38.svg',
  },
  {
    id: 39,
    path: '/images/previewerDefaultImages/widgets/icons/widgetIcon39.svg',
  },
  {
    id: 40,
    path: '/images/previewerDefaultImages/widgets/icons/widgetIcon40.svg',
  },
  {
    id: 41,
    path: '/images/previewerDefaultImages/widgets/icons/widgetIcon41.svg',
  },
  {
    id: 42,
    path: '/images/previewerDefaultImages/widgets/icons/widgetIcon42.svg',
  },
  {
    id: 43,
    path: '/images/previewerDefaultImages/widgets/icons/widgetIcon43.svg',
  },
];

export const predefinedWidgetImages: ({ id: number } & Record<Orientation, string>)[] = [
  {
    id: 1,
    [Orientation.Vertical]: '/images/previewerDefaultImages/widgets/images/vertical/widgetImage1.png',
    [Orientation.Horizontal]: '/images/previewerDefaultImages/widgets/images/horizontal/widgetImage1.png',
  },
  {
    id: 2,
    [Orientation.Vertical]: '/images/previewerDefaultImages/widgets/images/vertical/widgetImage2.png',
    [Orientation.Horizontal]: '/images/previewerDefaultImages/widgets/images/horizontal/widgetImage2.png',
  },
  {
    id: 3,
    [Orientation.Vertical]: '/images/previewerDefaultImages/widgets/images/vertical/widgetImage3.png',
    [Orientation.Horizontal]: '/images/previewerDefaultImages/widgets/images/horizontal/widgetImage3.png',
  },
  {
    id: 4,
    [Orientation.Vertical]: '/images/previewerDefaultImages/widgets/images/vertical/widgetImage4.png',
    [Orientation.Horizontal]: '/images/previewerDefaultImages/widgets/images/horizontal/widgetImage4.png',
  },
  {
    id: 5,
    [Orientation.Vertical]: '/images/previewerDefaultImages/widgets/images/vertical/widgetImage5.png',
    [Orientation.Horizontal]: '/images/previewerDefaultImages/widgets/images/horizontal/widgetImage5.png',
  },
  {
    id: 6,
    [Orientation.Vertical]: '/images/previewerDefaultImages/widgets/images/vertical/widgetImage6.png',
    [Orientation.Horizontal]: '/images/previewerDefaultImages/widgets/images/horizontal/widgetImage6.png',
  },
  {
    id: 7,
    [Orientation.Vertical]: '/images/previewerDefaultImages/widgets/images/vertical/widgetImage7.png',
    [Orientation.Horizontal]: '/images/previewerDefaultImages/widgets/images/horizontal/widgetImage7.png',
  },
  {
    id: 8,
    [Orientation.Vertical]: '/images/previewerDefaultImages/widgets/images/vertical/widgetImage8.png',
    [Orientation.Horizontal]: '/images/previewerDefaultImages/widgets/images/horizontal/widgetImage8.png',
  },
  {
    id: 9,
    [Orientation.Vertical]: '/images/previewerDefaultImages/widgets/images/vertical/widgetImage9.png',
    [Orientation.Horizontal]: '/images/previewerDefaultImages/widgets/images/horizontal/widgetImage9.png',
  },
  {
    id: 10,
    [Orientation.Vertical]: '/images/previewerDefaultImages/widgets/images/vertical/widgetImage10.png',
    [Orientation.Horizontal]: '/images/previewerDefaultImages/widgets/images/horizontal/widgetImage10.png',
  },
  {
    id: 11,
    [Orientation.Vertical]: '/images/previewerDefaultImages/widgets/images/vertical/widgetImage11.png',
    [Orientation.Horizontal]: '/images/previewerDefaultImages/widgets/images/horizontal/widgetImage11.png',
  },
  {
    id: 12,
    [Orientation.Vertical]: '/images/previewerDefaultImages/widgets/images/vertical/widgetImage12.png',
    [Orientation.Horizontal]: '/images/previewerDefaultImages/widgets/images/horizontal/widgetImage12.png',
  },
  {
    id: 13,
    [Orientation.Vertical]: '/images/previewerDefaultImages/widgets/images/vertical/widgetImage13.png',
    [Orientation.Horizontal]: '/images/previewerDefaultImages/widgets/images/horizontal/widgetImage13.png',
  },
  {
    id: 14,
    [Orientation.Vertical]: '/images/previewerDefaultImages/widgets/images/vertical/widgetImage14.png',
    [Orientation.Horizontal]: '/images/previewerDefaultImages/widgets/images/horizontal/widgetImage14.png',
  },
  {
    id: 15,
    [Orientation.Vertical]: '/images/previewerDefaultImages/widgets/images/vertical/widgetImage15.png',
    [Orientation.Horizontal]: '/images/previewerDefaultImages/widgets/images/horizontal/widgetImage15.png',
  },
  {
    id: 16,
    [Orientation.Vertical]: '/images/previewerDefaultImages/widgets/images/vertical/widgetImage16.png',
    [Orientation.Horizontal]: '/images/previewerDefaultImages/widgets/images/horizontal/widgetImage16.png',
  },
  {
    id: 17,
    [Orientation.Vertical]: '/images/previewerDefaultImages/widgets/images/vertical/widgetImage17.png',
    [Orientation.Horizontal]: '/images/previewerDefaultImages/widgets/images/horizontal/widgetImage17.png',
  },
  {
    id: 18,
    [Orientation.Vertical]: '/images/previewerDefaultImages/widgets/images/vertical/widgetImage18.png',
    [Orientation.Horizontal]: '/images/previewerDefaultImages/widgets/images/horizontal/widgetImage18.png',
  },
  {
    id: 19,
    [Orientation.Vertical]: '/images/previewerDefaultImages/widgets/images/vertical/widgetImage19.png',
    [Orientation.Horizontal]: '/images/previewerDefaultImages/widgets/images/horizontal/widgetImage19.png',
  },
  {
    id: 20,
    [Orientation.Vertical]: '/images/previewerDefaultImages/widgets/images/vertical/widgetImage20.png',
    [Orientation.Horizontal]: '/images/previewerDefaultImages/widgets/images/horizontal/widgetImage20.png',
  },
];

export const predefinedVerticalWidgetImages: PredefinedTabImage[] = predefinedWidgetImages.map(image => ({
  id: image.id,
  path: image[Orientation.Vertical],
}));

export const predefinedHorizontalWidgetImages: PredefinedTabImage[] = predefinedWidgetImages.map(image => ({
  id: image.id,
  path: image[Orientation.Horizontal],
}));

export const redundantWidgets = [
  AppLayoutFeatureEnum.PreviewExploreSchedule,
  AppLayoutFeatureEnum.PreviewWorkoutTracking,
  AppLayoutFeatureEnum.PreviewVirtualClasses,
  AppLayoutFeatureEnum.PreviewVirtualClassesOnTheTab,
];

type AppLayoutBasicFeature =
  | AppLayoutFeatureEnum.PreviewExtras
  | AppLayoutFeatureEnum.PreviewRequestTrainer
  | AppLayoutFeatureEnum.PreviewReferAFriend;

// @ts-ignore
export const predefinedWidgetImagesMapping: Record<AppLayoutFeatureEnum, PredefinedTabImage> = {
  [AppLayoutFeatureEnum.PreviewExtras]: predefinedVerticalWidgetImages[18],
  [AppLayoutFeatureEnum.PreviewRequestTrainer]: predefinedVerticalWidgetImages[16],
  [AppLayoutFeatureEnum.PreviewReferAFriend]: predefinedVerticalWidgetImages[17],
  [AppLayoutFeatureEnum.PreviewLocationFinder]: predefinedVerticalWidgetImages[19],
  [AppLayoutFeatureEnum.PreviewRewards]: predefinedVerticalWidgetImages[17],
};

export const predefinedWidgetIconsMapping: Record<AppLayoutBasicFeature, PredefinedTabIcon> = {
  [AppLayoutFeatureEnum.PreviewExtras]: predefinedWidgetIcons[10],
  [AppLayoutFeatureEnum.PreviewRequestTrainer]: predefinedWidgetIcons[0],
  [AppLayoutFeatureEnum.PreviewReferAFriend]: predefinedWidgetIcons[0],
};

// @ts-ignore
export const defaultWidgetStyles: Record<AppLayoutFeatureEnum, WidgetViewStyle> = {
  [AppLayoutFeatureEnum.PreviewExtras]: WidgetViewStyle.Icon,
  [AppLayoutFeatureEnum.PreviewRequestTrainer]: WidgetViewStyle.Image,
  [AppLayoutFeatureEnum.PreviewReferAFriend]: WidgetViewStyle.Image,
  [AppLayoutFeatureEnum.PreviewRewards]: WidgetViewStyle.Icon,
};

// @ts-ignore
export const defaultWidgetStrings: Record<AppLayoutFeatureEnum, Record<string, any>> = {
  [AppLayoutFeatureEnum.PreviewExtras]: {
    title: 'appPreviewer.labels.defaultFeaturesTitles.news',
    callToAction: 'appPreviewer.labels.stayUpdated',
  },
  [AppLayoutFeatureEnum.PreviewRequestTrainer]: {
    title: 'appPreviewer.labels.trainingGuidance',
    callToAction: 'appPreviewer.labels.requestTrainer',
  },
  [AppLayoutFeatureEnum.PreviewReferAFriend]: {
    title: 'appPreviewer.labels.inviteFriends',
    callToAction: 'appPreviewer.labels.sendInvite',
  },
  [AppLayoutFeatureEnum.PreviewActivityFeed]: {
    title: 'appPreviewer.labels.activityFeed',
  },
  [AppLayoutFeatureEnum.PreviewDeals]: {
    title: 'appPreviewer.labels.deals',
  },
  [AppLayoutFeatureEnum.PreviewGoalCenter]: {
    title: 'appPreviewer.labels.goal_plural',
  },
  [AppLayoutFeatureEnum.PreviewChallenges]: {
    title: 'appPreviewer.labels.defaultFeaturesTitles.challenges',
  },
  [AppLayoutFeatureEnum.PreviewLocationFinder]: {
    title: 'appPreviewer.labels.homeLocation',
  },
  [AppLayoutFeatureEnum.PreviewRewards]: {
    title: 'appPreviewer.labels.rewards',
  },
};
