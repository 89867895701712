import React, { FC, ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import { withFormikDevtools } from 'formik-devtools-extension';
import {
  Box,
  ConditionalRender,
  Divider,
  FormikOnChange,
  FormikScrollToError,
  LinkCore,
  MultiLanguageTextField,
  RadioGroupFormField,
  Stack,
  Typography,
} from '@egym/ui';
import { WidgetPreview, AddFullTabAndMoveRemainingFeatures } from '@components';
import { FeatureSize } from '@constants';
import { FullTabWidgetPreferencesFormProps } from './FullTabWidgetPreferencesFormProps';

const FullTabWidgetPreferencesForm: FC<FullTabWidgetPreferencesFormProps> = ({
  currentWidget,
  formConfig,
  onFormChanged,
  sizeOption,
  previewComponentConfigs,
  previewerPalette,
  previewerState,
  availableLanguages,
  openLanguagesMenuTab,
  isFullTab,
  currentAppLoutTab,
  widgetWithTab,
  appLayoutTabs,
  moveRemainingFeaturesFormRef,
}) => {
  const { t } = useTranslation();
  return (
    <Stack spacing={7} sx={{ px: 8, maxWidth: '710px', boxSizing: 'border-box' }}>
      <Formik {...formConfig}>
        {formProps =>
          withFormikDevtools(
            formProps,
            <Form>
              <FormikOnChange onFormChanged={onFormChanged} />
              <FormikScrollToError>
                <Stack spacing={7}>
                  <Stack spacing={5} sx={{ pt: 7 }}>
                    <Typography variant="h5Bold">{t('common.labels.size') as ReactNode}</Typography>
                    <Stack>
                      <Field
                        id="name"
                        name="name"
                        component={RadioGroupFormField}
                        hiddenLabel
                        row
                        optionsSpacing={6}
                        optionsWrapperSx={{
                          width: '100%',
                          justifyContent: 'space-between',
                        }}
                        formControlLabelWrapperSx={{
                          width: '50%',
                        }}
                        optionLabelTypographyProps={{
                          variant: 'body1',
                        }}
                        options={[
                          {
                            value: sizeOption[FeatureSize.Compact],
                            label: 'common.labels.compact',
                            sx: { p: 0, mr: 3 },
                          },
                          {
                            value: sizeOption[FeatureSize.FullTab],
                            label: 'common.labels.fullTab',
                            sx: { p: 0, mr: 3 },
                          },
                        ]}
                      />
                      <Stack direction="row" spacing={5} sx={{ justifyContent: 'space-between', mt: 4 }}>
                        {Object.entries(previewComponentConfigs).map(([key, { component, previewWidgetProps }]) => (
                          <WidgetPreview
                            key={key}
                            widget={{
                              component,
                              props: {
                                ...currentWidget,
                                name: sizeOption[key],
                                preferences: { title: formProps.values.title },
                              },
                            }}
                            previewerPalette={previewerPalette}
                            previewerState={previewerState}
                            appLayoutTabs={[previewWidgetProps.appPreviewerTab]}
                            wrapperSx={{
                              height: '100%',
                              alignItems: 'center',
                              justifyContent: 'flex-start',
                              minHeight: '385px',
                            }}
                            appPreviewerProps={{
                              id: `form-item-${sizeOption[key]}`,
                              size: 'medium',
                              wrapperSx: {
                                position: 'absolute',
                                // hide previewer footer with tabs
                                bottom: '-55px',
                              },
                              fadeTimeout: 0,
                            }}
                            scrollAreaSx={{
                              px: 0,
                              py: 6,
                            }}
                          />
                        ))}
                      </Stack>
                    </Stack>
                  </Stack>
                  <ConditionalRender condition={!isFullTab}>
                    <>
                      <Divider flexItem />
                      <Stack spacing={5}>
                        <Typography variant="h5Bold">
                          {t('appDesign.labels.widgets.featureName') as ReactNode}*
                        </Typography>
                        <MultiLanguageTextField
                          name="title"
                          languages={availableLanguages}
                          inputProps={{ maxLength: 20 }}
                        />
                      </Stack>
                      <Divider flexItem />
                      <Box sx={{ mt: 10 }}>
                        <Typography variant="body2" sx={{ color: 'grey.500' }}>
                          <Trans
                            i18nKey="appDesign.labels.customizeLayout.tabsSettingsDescription"
                            components={[
                              <LinkCore
                                component="button"
                                sx={{ textDecoration: 'underline' }}
                                color="inherit"
                                onClick={() => openLanguagesMenuTab(formProps)}
                              />,
                            ]}
                          />
                        </Typography>
                      </Box>
                    </>
                  </ConditionalRender>
                </Stack>
              </FormikScrollToError>
            </Form>,
          )
        }
      </Formik>
      <ConditionalRender
        condition={isFullTab && Boolean(currentAppLoutTab?.features?.length && currentAppLoutTab.features.length > 1)}
      >
        <AddFullTabAndMoveRemainingFeatures
          moveRemainingFeaturesFormRef={moveRemainingFeaturesFormRef}
          widget={widgetWithTab}
          appLayoutTabs={appLayoutTabs}
          previewerPalette={previewerPalette}
          previewerState={previewerState}
        />
      </ConditionalRender>
    </Stack>
  );
};

export default FullTabWidgetPreferencesForm;
