import React, { FC, ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import { withFormikDevtools } from 'formik-devtools-extension';
import {
  Box,
  ColorPickerFormField,
  Divider,
  FileUploadFormField,
  FormikOnChangeWithDebounce,
  FormikScrollToError,
  LinkCore,
  MultiLanguageTextField,
  PickerType,
  Stack,
  Typography,
} from '@egym/ui';
import { FileFormatName, getBytesFromMb } from '@egym/utils';
import { PredefinedImagesLibraryPopover } from '@components';
import { predefinedVerticalWidgetImages } from '@constants';
import { LocationFinderWidgetPreferencesFormProps } from './LocationFinderWidgetPreferencesFormProps';

const LocationFinderWidgetPreferencesForm: FC<LocationFinderWidgetPreferencesFormProps> = ({
  formProps,
  availableLanguages,
  openLanguagesMenuTab,
  presetColors,
  onSelectPredefinedImage,
  onFormChanged,
  onCustomImagePick,
  imageDimensions,
}) => {
  const { t } = useTranslation();

  return (
    <Formik {...formProps}>
      {props =>
        withFormikDevtools(
          props,
          <Form>
            <FormikOnChangeWithDebounce onFormChanged={onFormChanged} debounce={300} />
            <FormikScrollToError>
              <Stack spacing={7} direction="column" sx={{ px: 8, maxWidth: '710px', boxSizing: 'border-box' }}>
                <Stack spacing={5} sx={{ pt: 7 }}>
                  <Typography variant="h5Bold">{t('common.labels.image') as ReactNode}</Typography>
                  <Field
                    name="image"
                    component={FileUploadFormField}
                    accept={[FileFormatName.PNG, FileFormatName.JPG]}
                    acceptHintsCommon={[
                      {
                        tKey: 'common.fileUpload.atLeastWidthAndHeight',
                        tKeyParams: { widthPixels: imageDimensions.width, heightPixels: imageDimensions.height },
                      },
                    ]}
                    minImageDimension={[imageDimensions.width, imageDimensions.height]}
                    cropperProps={{
                      ratio: imageDimensions.ratio,
                    }}
                    maxSize={getBytesFromMb(10)}
                    onFilePick={() => onCustomImagePick(props)}
                    wrapperSx={{ mt: 10 }}
                    uploadPaneProps={{
                      justifyContent: 'space-between',
                      spacing: 6,
                    }}
                    previewWrapperSx={{
                      height: '100%',
                      width: '100%',
                      justifyContent: 'flex-start',
                      alignItems: 'baseline',
                      p: 0,
                    }}
                    previewImageProps={(filePreviewResult: string) => ({
                      sx: {
                        height: '90px',
                        width: '100%',
                        background: `url(${filePreviewResult}) no-repeat center/100%`,
                      },
                      component: 'div',
                    })}
                    uploadPaneWrapperSx={{
                      minHeight: '300px',
                      '& > *': {
                        width: '50%',
                        height: '100%',
                        flex: 1,
                      },
                    }}
                    uploadImageTool={
                      <Stack spacing={8} sx={{ ml: 5, mt: 8, mb: 7 }}>
                        <Field
                          name="imageOverlay"
                          component={ColorPickerFormField}
                          fieldLabel="common.labels.imageOverlay"
                          presetColors={presetColors}
                          pickers={[PickerType.OneColor, PickerType.Gradient]}
                        />
                        <PredefinedImagesLibraryPopover
                          onSelectImage={image => onSelectPredefinedImage(image, props)}
                          images={predefinedVerticalWidgetImages}
                          predefinedImageId={props.values.imagePredefinedId || 1}
                          wrapperSx={{
                            '& > button': {
                              justifyContent: 'initial',
                            },
                          }}
                        />
                      </Stack>
                    }
                  />
                </Stack>
                <Divider flexItem />
                <Stack spacing={5}>
                  <Typography variant="h5Bold">{t('appDesign.labels.widgets.name') as ReactNode}*</Typography>
                  <MultiLanguageTextField name="title" languages={availableLanguages} inputProps={{ maxLength: 20 }} />
                </Stack>
                <Divider flexItem />
                <Box sx={{ mt: 10 }}>
                  <Typography variant="body2" sx={{ color: 'grey.500' }}>
                    <Trans
                      i18nKey="appDesign.labels.customizeLayout.tabsSettingsDescription"
                      components={[
                        <LinkCore
                          component="button"
                          sx={{ textDecoration: 'underline' }}
                          color="inherit"
                          onClick={() => openLanguagesMenuTab(props)}
                        />,
                      ]}
                    />
                  </Typography>
                </Box>
              </Stack>
            </FormikScrollToError>
          </Form>,
        )
      }
    </Formik>
  );
};

export default LocationFinderWidgetPreferencesForm;
