import React from 'react';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import { Stack } from '@egym/ui';
import { AppLayoutFeatureComponentProps } from '../../../../AppPreviewerProps';
import PreviewContainer from '../../../PreviewContainer';
import PreviewFeatureTitle from '../../../PreviewFeatureTitle';
import { mockHealthBenefits } from './data';
import PreviewHealthBenefitItem from './PreviewHealthBenefitItem';

const PreviewHealthBenefits: React.FC<AppLayoutFeatureComponentProps> = ({ tFunction, palette, wrapperSx }) => {
  const swipeableStyles = {
    root: {
      padding: '0 11.25px',
    },
    slideContainer: {
      padding: '0 3.75px',
      boxSizing: 'border-box' as any,
    },
  };

  const carouselIndex = 1;

  return (
    <div>
      <PreviewContainer wrapperSx={wrapperSx}>
        <PreviewFeatureTitle tFunction={tFunction} palette={palette} title="appPreviewer.labels.healthBenefits" />
      </PreviewContainer>

      <Stack>
        <SwipeableViews index={carouselIndex} style={swipeableStyles.root} slideStyle={swipeableStyles.slideContainer}>
          {mockHealthBenefits.map(it => {
            return <PreviewHealthBenefitItem tFunction={tFunction} key={it.id} {...it} />;
          })}
        </SwipeableViews>
        <Stack direction="row" alignItems="center" justifyContent="center" sx={{ mt: '10px' }} spacing="7px">
          {mockHealthBenefits.map((it, index) => {
            return (
              <div
                key={it.id}
                style={{
                  width: '7px',
                  height: '7px',
                  borderRadius: '50%',
                  background: carouselIndex === index ? 'rgb(0, 0, 0)' : 'rgb(234,234,234)',
                }}
              />
            );
          })}
        </Stack>
      </Stack>
    </div>
  );
};

export default PreviewHealthBenefits;
