import React, { forwardRef } from 'react';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import { AppLayoutFeatureComponentProps } from '../../../../AppPreviewerProps';
import PreviewContainer from '../../../PreviewContainer';
import PreviewFeatureTitle from '../../../PreviewFeatureTitle';
import { trainingTemplatesMock } from './data';
import PreviewTrainingPlanItem from './PreviewTrainingPlanItem';

const PreviewTrainingPlans = forwardRef<HTMLDivElement, AppLayoutFeatureComponentProps>(
  ({ tFunction, palette, wrapperSx, itemWrapperSx }, ref) => {
    const swipeableStyles = {
      root: {
        padding: '0 11.25px',
      },
      slideContainer: {
        padding: '0 3.75px',
        boxSizing: 'border-box' as any,
      },
    };

    return (
      <div ref={ref}>
        <PreviewContainer wrapperSx={wrapperSx}>
          <PreviewFeatureTitle
            tFunction={tFunction}
            palette={palette}
            title="appPreviewer.labels.trainingPlans"
            withViewAll
          />
        </PreviewContainer>

        <SwipeableViews style={swipeableStyles.root} slideStyle={swipeableStyles.slideContainer}>
          {trainingTemplatesMock.map(plan => {
            return <PreviewTrainingPlanItem tFunction={tFunction} key={plan.id} wrapperSx={itemWrapperSx} {...plan} />;
          })}
        </SwipeableViews>
      </div>
    );
  },
);

export default PreviewTrainingPlans;
