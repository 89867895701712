import React, { FC, ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import {
  Box,
  Divider,
  FormikOnChange,
  FormikScrollToError,
  LinkCore,
  MultiLanguageTextField,
  RadioGroupFormField,
  Stack,
  Typography,
} from '@egym/ui';
import { AppLayoutFeatureEnum, PreviewGroupWidgetSkeleton, WidgetPreview } from '@components';
import { Orientation } from '@types';
import { groupWidgetTypeOptions } from './constants';
import { GroupWebWidgetPreferencesFormProps } from './GroupWebWidgetPreferencesFormProps';

const GroupWebWidgetPreferencesForm: FC<GroupWebWidgetPreferencesFormProps> = ({
  formConfig,
  availableLanguages,
  openLanguagesMenuTab,
  onFormChanged,
}) => {
  const { t } = useTranslation();

  return (
    <Formik {...formConfig}>
      {formProps => (
        <Stack
          component={Form}
          spacing={7}
          direction="column"
          sx={{ px: 8, maxWidth: '710px', boxSizing: 'border-box', flex: 1 }}
        >
          <FormikScrollToError />
          <FormikOnChange onFormChanged={onFormChanged} />
          <Stack sx={{ pt: 7 }}>
            <Typography variant="h5Bold">{t('appDesign.labels.widgets.layout') as ReactNode}</Typography>
            <Stack>
              <Field
                id="orientation"
                name="orientation"
                component={RadioGroupFormField}
                hiddenLabel
                row
                optionsSpacing={6}
                wrapperSx={{ mt: 6 }}
                options={groupWidgetTypeOptions}
                optionsWrapperSx={{
                  width: '100%',
                  justifyContent: 'space-between',
                }}
                formControlLabelWrapperSx={{
                  width: '50%',
                }}
                optionLabelTypographyProps={{
                  variant: 'body1',
                }}
              />
              <Stack direction="row" spacing={6} sx={{ justifyContent: 'space-between', mt: 4 }}>
                <WidgetPreview
                  wrapperSx={{ width: '50%', maxHeight: '234px' }}
                  scrollAreaSx={{ px: 5, pt: 6, boxSizing: 'border-box' }}
                  componentWrapperSx={{ p: 0 }}
                  widget={{
                    component: PreviewGroupWidgetSkeleton,
                    props: {
                      name: AppLayoutFeatureEnum.PreviewGroupWebWidget,
                      preferences: { orientation: Orientation.Vertical },
                    },
                  }}
                  previewerState={{}}
                  previewerPalette={{ primary: '', secondary: '' }}
                />
                <WidgetPreview
                  wrapperSx={{ width: '50%', justifyContent: 'flex-start' }}
                  scrollAreaSx={{ px: 5, pt: 6, boxSizing: 'border-box', overflowY: 'hidden' }}
                  componentWrapperSx={{ p: 0 }}
                  widget={{
                    component: PreviewGroupWidgetSkeleton,
                    props: {
                      name: AppLayoutFeatureEnum.PreviewGroupWebWidget,
                      preferences: { orientation: Orientation.Horizontal },
                    },
                  }}
                  previewerState={{}}
                  previewerPalette={{ primary: '', secondary: '' }}
                />
              </Stack>
            </Stack>
          </Stack>
          <Divider flexItem />
          <Stack spacing={5}>
            <Typography variant="h5Bold" sx={{ lineHeight: 1.17 }}>
              {t('appDesign.labels.widgets.groupName') as ReactNode}*
            </Typography>
            <MultiLanguageTextField name="title" languages={availableLanguages} inputProps={{ maxLength: 20 }} />
          </Stack>
          <Divider flexItem />
          <Box sx={{ mt: 10 }}>
            <Typography variant="body2" sx={{ color: 'grey.500' }}>
              <Trans
                i18nKey="appDesign.labels.customizeLayout.tabsSettingsDescription"
                components={[
                  <LinkCore
                    component="button"
                    sx={{ textDecoration: 'underline' }}
                    color="inherit"
                    onClick={() => openLanguagesMenuTab(formProps)}
                  />,
                ]}
              />
            </Typography>
          </Box>
        </Stack>
      )}
    </Formik>
  );
};

export default GroupWebWidgetPreferencesForm;
