import React, { forwardRef } from 'react';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import { AppLayoutFeatureComponentProps } from '../../../../AppPreviewerProps';
import PreviewContainer from '../../../PreviewContainer';
import PreviewFeatureTitle from '../../../PreviewFeatureTitle';
import { mockDeals } from './data';
import PreviewDealItem from './PreviewDealItem';

const PreviewDeals = forwardRef<HTMLDivElement, AppLayoutFeatureComponentProps>(
  ({ tFunction, palette, previewerState, wrapperSx, itemWrapperSx, widgetProps }, ref) => {
    const swipeableStyles = {
      root: {
        padding: '0 11.25px',
      },
      slideContainer: {
        padding: '0 3.75px',
        boxSizing: 'border-box' as any,
      },
    };

    return (
      <div ref={ref}>
        <PreviewContainer wrapperSx={wrapperSx}>
          <PreviewFeatureTitle
            tFunction={tFunction}
            palette={palette}
            title={
              widgetProps?.preferences?.title?.[previewerState.selectedLocale as string] || 'appPreviewer.labels.deals'
            }
            withViewAll
          />
        </PreviewContainer>

        <SwipeableViews style={swipeableStyles.root} slideStyle={swipeableStyles.slideContainer}>
          {mockDeals.map(deal => {
            return (
              <PreviewDealItem
                tFunction={tFunction}
                palette={palette}
                previewerState={previewerState}
                key={deal.id}
                wrapperSx={itemWrapperSx}
                {...deal}
              />
            );
          })}
        </SwipeableViews>
      </div>
    );
  },
);

export default PreviewDeals;
